import logo from '../assets/logo192.png';
import '../App.css';
import {useState} from "react";
import {useParams} from "react-router-dom";

const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/

function PasswordReset() {
    const {token} = useParams()
    const [password, setPassword] = useState('')
    const [passwordRepeat, setPasswordRepeat] = useState('')

    const [passwordError, setPasswordError] = useState(false)
    const [passwordSuccess, setPasswordSuccess] = useState(false)

    const onChangePassword = async () => {
        setPasswordError(false)
        setPasswordSuccess(false)
        if(!PASSWORD_REGEX.test(password) || password !== passwordRepeat) {
            setPasswordError(true)
            return
        }

        const url = 'https://api.vibar.app/api/v1/auth/validate'

        fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                token,
                password
            }),
        }).then((r) => {
            if(r.status !== 200) {
                setPasswordError(true)
                return
            }
            setPasswordSuccess(true)
        })
    }

    return (
        <div className="App">
            <header className="App-header">
                <div className="App-container">
                    <img src={logo} className="App-logo" alt="logo" />
                    <p className="App-text">Vibar</p>
                </div>
                <div className="inputWrapper">
                    <p>Nueva contraseña</p>
                    <input type={'password'} placeholder={'Nueva contraseña'} onChange={(a) => setPassword(a.target.value)} />
                </div>
                <div className="inputWrapper">
                    <p>Repetir contraseña</p>
                    <input type={'password'} placeholder={'Repita la contraseña'} onChange={(a) => setPasswordRepeat(a.target.value)} />
                </div>
                <button onClick={onChangePassword}>Cambiar contraseña</button>
                {passwordError && <p className={"App-error"}>Error al cambiar la contraseña. Minimo 8 caracteres, numero, mayuscula y minuscula</p>}
                {passwordSuccess && <p className={"App-success"}>Contraseña cambiada correctamente</p>}
            </header>
            <div className={"App-bg"}/>
        </div>
    );
}

export default PasswordReset;
