import React from 'react';
import { useCountdown } from '../../hooks/useCountdown';
import './index.css'

const DateTimeDisplay = ({ value, type }) => {
    return (
        <div className={'countdown'}>
            <p>{value}</p>
            <span>{type}</span>
        </div>
    );
};

const ShowCounter = ({ days, hours, minutes, seconds }) => {
    return (
        <div className="show-counter">
            <DateTimeDisplay value={days} type={'Días'} />
            <p>:</p>
            <DateTimeDisplay value={hours} type={'Horas'} />
            <p>:</p>
            <DateTimeDisplay value={minutes} type={'Minutos'} />
            <p>:</p>
            <DateTimeDisplay value={seconds} type={'Segundos'} />
        </div>
    );
};

export const CountdownTimer = ({ targetDate }) => {
    const [days, hours, minutes, seconds] = useCountdown(targetDate);

    if (days + hours + minutes + seconds <= 0) {
        return null;
    } else {
        return (
            <ShowCounter
                days={days}
                hours={hours}
                minutes={minutes}
                seconds={seconds}
            />
        );
    }
};
