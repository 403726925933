import logo from '../assets/logo192.png';
import './privacy.css'

function PrivacyPolicy() {
    return (
        <div className="App">
            <header className="App-header">
                <div className="App-container">
                    <img src={logo} className="App-logo" alt="logo" />
                    <p className="App-text">Vibar</p>
                </div>
                <div className={'policyWrapper'}>
                    <p className={"text"}>
                        Ponemos a disposición de los usuarios de Vibar, en adelante, la aplicación, la presente <b>Política de Privacidad y de Tratamiento de Datos Personales</b> (en adelante, la “Política”), con la finalidad de ofrecer a los usuarios de la aplicación Vibar información detallada sobre el tratamiento de sus datos personales.
                    </p>

                    <p className={"text"}>
                        Esta Política resultará de aplicación a Vibar (en adelante, la Aplicación) y todas aquellas funcionalidades de la misma. Podremos modificar esta Política de Privacidad cuando resulte necesario. En caso de introducirse modificaciones, se lo comunicaremos a través de la página web o bien por otros medios, con la finalidad de que pueda conocer las nuevas condiciones de Privacidad. El hecho de continuar haciendo uso de las funcionalidades puestas a disposición por nuestra compañía después de haberle notificado las referidas modificaciones supondrá que está de acuerdo con las mismas, salvo para los supuestos en los que sea necesario su consentimiento expreso, donde lo solicitaremos.
                    </p>

                    <p className={"text"}>
                        A efectos de esta Política de Privacidad, se podrá entender “usuario” como aquella persona física que hace uso de las funcionalidades que Vibar ofrece a través de su aplicación.
                    </p>

                    <p className={"text"}>
                        En cumplimiento de lo dispuesto en el Reglamento General de Protección de datos, la Ley Orgánica 3/2018, de 5 de diciembre de Protección de datos Personales y garantía de los derechos digitales, y en la Ley 34/2002, de 11 de julio, de servicios de la sociedad de la información y comercio electrónico, se informa al Usuario del Portal de lo siguiente:
                    </p>

                    <p className={"title"}>RESPONSABLE Y ENCARGADO DEL TRATAMIENTO</p>
                    <p className={"text"}>
                        Para garantizar la adecuada gestión en el tratamiento de sus datos Vibar tiene designado un buzón de contacto, a quien podrá dirigirse para cualquier cuestión que precise y con quien puede contactar en la dirección electrónica info@vibar.app.
                    </p>

                    <p className={"title"}>DATOS TRATADOS POR VIBAR Y FUENTES DE LAS QUE PROCEDEN</p>
                    <p className={"text"}>
                        A continuación, se informa al Usuario de los datos que podrán ser tratados por Vibar y de las fuentes de las que proceden.
                    </p>

                    <p className={"text"}>
                        <b>Fuentes de procedencia de los datos:</b> Datos aportados por el usuario mediante la cumplimentación de los campos puestos a disposición para el usuario, por cualquier otra vía mediante la que el usuario entable comunicación con Vibar, por la comunicación entre el usuario y Vibar.
                    </p>

                    <p className={"text"}>
                        Email, nombre y apellidos, en caso de ser aportados por Google, Apple, o servicios similares utilizados por el usuario para registrarse y acceder a Vibar.
                    </p>

                    <p className={"text"}>
                        <b>Categorías de datos tratados:</b> Vibar podrá tratar datos personales de las siguientes categorías, en función de la relación entablada con el usuario:
                    </p>
                        <ul>
                            <li className={"text"}>Datos identificativos (p.ej., nombre y apellidos, correo electrónico, dirección IP, etc.).</li>
                        </ul>

                    <p className={"title"}>FINALIDADES Y LEGITIMACIÓN PARA EL TRATAMIENTO DE SUS DATOS</p>
                    <p className={"text"}>
                        Vibar podrá tratar los datos indicados para la realización de diversas finalidades y propósitos que se indican a continuación:
                    </p>
                        <ul>
                            <li className={"text"}>1. Almacenamiento y gestión de las consultas que pudieran realizarse a través de los formularios de contacto.</li>
                            <li className={"text"}>2. El envío de newsletters a los usuarios registrados, para mantenerle al día de estadísticas o novedades en la plataforma</li>
                        </ul>
                    <p className={"text"}>

                        Vibar trata los datos de carácter personal facilitados por los usuarios de acuerdo con las siguientes bases legitimadoras, en función de la tipología de datos que sean facilitados por el usuario, así como de las interacciones que el usuario entable con Vibar.
                    </p>
                    <ul>
                        <li className={"text"}>Almacenamiento y gestión de las consultas que pudieran realizarse a través de los formularios de contacto, gestión de la participación del usuario en valoraciones y las finalidades directamente relacionadas con la participación y el envío de Newsletters a los usuarios registrados, para mantenerles al día de las novedades de la Aplicación: <b>desarrollo y mantenimiento de la relación jurídica y / o contractual entablada entre el Usuario y Vibar y desarrollo y mantenimiento de la relación jurídica y / o contractual entablada entre el usuario y Vibar.</b></li>
                    </ul>

                    <p className={"title"}>COMUNICACIÓN DE LOS DATOS</p>
                    <p className={"text"}>
                        Los datos personales tratados para alcanzar las finalidades detalladas anteriormente podrán ser comunicados a los siguientes destinatarios en función de la base legitimadora de la comunicación.
                    </p>
                    <ul>
                        <li className={"text"}>Proveedores necesarios para el cumplimiento de las finalidades, con el fin único y exclusivo de cumplir con la relación de negocio creada. En ningún caso podrán dichos terceros proveedores realizar acciones comerciales o promocionales directas a los Usuarios ni comunicar dichos datos a terceras personas o entidades de su mismo grupo, cualquiera que fuera la finalidad de la comunicación, recayendo exclusivamente sobre dichos terceros proveedores la responsabilidad que corresponda en caso de incumplimiento de lo anterior.</li>
                        <li className={"text"}>Otras sociedades pertenecientes a Vibar.</li>
                    </ul>

                    <p className={"title"}>CONSERVACIÓN DE DATOS</p>
                    <p className={"text"}>
                        Trataremos sus datos personales hasta que deje de ser usuario de la aplicación, momento a partir del cual procederemos a bloquearlos hasta su definitiva supresión conforme a los plazos legalmente habilitados.
                    </p>

                    <p className={"title"}>GARANTÍAS APORTADAS POR EL USUARIO SOBRE LOS DATOS PROPORCIONADOS</p>
                    <p className={"text"}>
                        El usuario garantiza que los datos que aporte son verdaderos, exactos, completos y se encuentran actualizados, siendo responsable de cualquier daño o perjuicio, directo o indirecto, que pudiera ocasionarse como consecuencia del incumplimiento de tal obligación.
                    </p>

                    <p className={"text"}>
                        El Usuario se compromete y obliga a comunicar de forma inmediata Vibar cualquier modificación relevante de sus datos personales a fin de que la información contenida en sus ficheros esté en todo momento actualizada y no contenga errores.
                    </p>

                    <p className={"title"}>MEDIDAS ADOPTADAS PARA PROTEGER SUS DATOS</p>
                    <p className={"text"}>
                        Con el fin de garantizar la seguridad y confidencialidad de sus datos, Vibar se compromete a tratar de forma absolutamente confidencial los datos de carácter personal del Usuario, haciendo uso de los mismos exclusivamente para las finalidades indicadas en esta política de privacidad.
                    </p>

                    <p className={"text"}>
                        Vibar ha adoptado los niveles de seguridad requeridos en materia de protección de datos y tiene implantadas las medidas de seguridad de índole técnica y organizativas necesarias que garantizan la seguridad de sus datos de personales y evitan su alteración, pérdida, tratamiento y/o acceso no autorizado, habida cuenta del estado de la tecnología, la naturaleza de los datos almacenados y los riesgos a que están expuestos, ya provengan de la acción humana o del medio físico o natural. En todo caso, debe tener en consideración que las medidas de seguridad en Internet no son inexpugnables.
                    </p>

                    <p className={"title"}>DERECHOS SOBRE SUS DATOS PERSONALES</p>
                    <p className={"text"}>
                        Vibar le informa que tiene derecho a obtener confirmación sobre si estamos tratando datos personales que le conciernen o no. Del mismo modo, Vibar le informa que usted cuenta con los siguientes derechos sobre sus datos de carácter personal:
                    </p>
                    <ul>
                        <li className={"text"}>Acceder a sus datos: tiene derecho a acceder a sus datos para conocer qué datos personales estamos tratando que le conciernen.</li>
                        <li className={"text"}>Solicitar la rectificación o supresión de sus datos: En determinadas circunstancias, Ud. tiene derecho a rectificar aquellos datos personales inexactos que le conciernen que sean objeto de tratamiento por nuestra parte o, incluso, a solicitarnos su supresión cuando, entre otros motivos, los datos ya no fueran necesarios para los fines que fueron recogidos.</li>
                        <li className={"text"}>Solicitar la limitación del tratamiento de sus datos: En determinadas circunstancias, Ud. tendrá derecho a solicitarnos la limitación del tratamiento de sus datos, en cuyo caso le informamos que únicamente los conservaremos para el ejercicio o defensa de reclamaciones tal y como prevé el Reglamento General de Protección de Datos.</li>
                        <li className={"text"}>A la portabilidad de sus datos: En determinadas circunstancias, Ud. tendrá derecho a recibir los datos personales que le incumban, y que nos haya facilitado, en un formato estructurado, de uso común y lectura mecánica, y a transmitirlos a otro responsable del tratamiento.</li>
                        <li className={"text"}>Oponerse al tratamiento de sus datos: En determinadas circunstancias y por motivos relacionados con su situación particular, Ud. tendrá derecho a oponerse al tratamiento de sus datos en cuyo caso dejaríamos de tratarlos salvo que la continuación en el tratamiento resulte necesaria por motivos legítimos imperiosos, o el ejercicio o la defensa de posibles reclamaciones. Asimismo, le recordamos que, en su caso, Ud. podrá oponerse al tratamiento de sus datos con fines promocionales.</li>
                        <li className={"text"}>Derecho a no ser objeto de decisiones automatizadas: Tiene Ud. Derecho a no ser objeto de una decisión basada únicamente en el tratamiento automatizado, incluida la elaboración de perfiles, que produzca efectos jurídicos sobre Ud. o le afecte significativamente de modo similar.</li>
                        <li className={"text"}>Derecho a retirar el consentimiento en cualquier momento, respecto a aquellas finalidades para las que lo otorgó, sin que ello afecte a la licitud del tratamiento basado en el consentimiento previo a su retirada.</li>
                    </ul>

                    <p className={"text"}>
                        Para el ejercicio de los citados derechos el interesado podrá ponerse en contacto dirigiendo un escrito a Vibar a info@vibar.app
                    </p>

                    <p className={"text"}>
                        Responderemos a sus peticiones a la mayor brevedad posible y, en todo caso, en el plazo de un mes desde la recepción de su solicitud. Dicho plazo podrá prorrogarse otros dos meses en caso necesario, teniendo en cuenta la complejidad y el número de solicitudes. En tal caso, Vibar informará al interesado de la prórroga dentro del primer mes desde la solicitud.
                    </p>

                    <p className={"text"}>
                        Vibar le informa que podrá presentar reclamación ante la Autoridad de Control competente en materia de protección de datos, especialmente cuando no haya obtenido satisfacción en el ejercicio de sus derechos, a estos efectos debe dirigirse ante la Agencia Española de Protección de Datos (www.aepd.es).
                    </p>

                    <p className={"text"}>
                        Con carácter previo a la presentación de dicha reclamación ante la Agencia Española de Protección de datos, usted puede dirigirse a info@vibar.app
                    </p>
                </div>
            </header>
            <div className={"App-bg"} />
        </div>
    );
}

export default PrivacyPolicy;
