import logo from '../assets/logo192.png';
import '../App.css';
import {CountdownTimer} from '../components/countdown-timer'
import {useSlogan} from "../hooks/useSlogan";
import {useEffect} from "react";

const LAUNCH_DAY = 1682899200000;

function Home() {
    const slogan = useSlogan(10000);

    useEffect(() => {
        if (navigator.userAgent.match(/Android/i)) {
            window.location.href = "https://play.google.com/store/apps/details?id=com.drivecheap";
        } else if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
            window.location.href = "https://apps.apple.com/us/app/vibar-precio-gasolina-espa%C3%B1a/id6447907944";
        }
    })

    return (
        <div className="App">
            <header className="App-header">
                <div className="App-container">
                    <img src={logo} className="App-logo" alt="logo" />
                    <p className="App-text">Vibar</p>
                </div>
                <div>
                    <p className={"App-slogan"}>{slogan}</p>
                    <CountdownTimer targetDate={LAUNCH_DAY} />
                </div>
            </header>
            <div className={"App-bg"}/>
        </div>
    );
}

export default Home;
