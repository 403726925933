import {useState, useEffect} from "react";

const vibarPhrases = [
    "Encuentra el mejor precio de la gasolina en segundos con Vibar",
    "Drive smart, save money",
    "Tu mejor compañero de viaje para ahorrar en gasolina",
    "Siéntete en control de tu consumo de gasolina",
    "Encuentra la gasolinera perfecta para ti, en cualquier lugar",
    "Ahorra en gasolina, invierte en aventuras",
    "Planea tus rutas y haz que cada kilómetro cuente",
    "Conduce de manera eficiente y reduce tu huella de carbono",
    "Toma el control de tus gastos en gasolina con Vibar",
    "Encuentra el mejor lugar para llenar el deposito y sigue adelante",
]
export const useSlogan = (timer) => {
    const [slogan, setSlogan] = useState(vibarPhrases[Math.floor(Math.random() * vibarPhrases.length)]);

    useEffect(() => {
        const interval = setInterval(() => {
            setSlogan(vibarPhrases[Math.floor(Math.random() * vibarPhrases.length)]);
        }, timer);

        return () => clearInterval(interval);
    }, [slogan, timer]);

    return slogan;
}
